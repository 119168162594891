/* chatbot dot */

.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chatbot-dot {
    width: 50px;
    height: 50px;
    background-color: var(--glowva-secondary-white);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border: 2px solid var(--glowva-tertiary-green);
    ;
    box-sizing: border-box;
}

.chatbot-window {
    position: absolute;
    bottom: 70px;
    right: 0;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* end chat bot dot*/
/* -------------------------------------------------------------------- */
/* chat page */

.chat-area {
    background-color: var(--glowva-secondary-white);
    border: 1.5px solid black;
    border-radius: 10px;
}

.chat-history {
    height: 100%;
}

/* .chat-dialogue-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.message-container {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 1rem;
}

.message-start-hint {
    font-size: 0.8rem;
    color: #6c757d;
    width: 20%;
    word-wrap: normal;
    border: 1.5px solid rgb(241, 241, 241);
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
    height: fit-content;
}

/* end chat page */

/* dialogue */
.user-says {
    background-color: var(--glowva-secondary-white);
    border: black 1px solid;
    border-radius: 8px;
}

.assistant-says {
    background-color: var(--glowva-primary);
    color: white;
    border-radius: 8px;
}

/* end dialogue */

.chatbot-dot-text {
    font-size: var(--glowva-font-size-xs);
}